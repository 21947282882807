export const DEFAULT_REGISTER_TITLES = {
  register_title: 'register',
  register_subtitle: '',
  login_title: 'login',
  login_subtitle: '',
};

export const DEFAULT_PAY_LABELS = {
  pay_title: 'when do you want to pay',
  pay_now: 'pay now',
  pay_later: 'pay later'
};

export const DEFAULT_SPLIT_ACCOUNT = {
  active: true,
  amount: true,
  item: true,
  evenly: true,
  order: true,
};

export const DEFAULT_CONSENT_LINKS = {
  privacy_policy: 'https://yumminn.com/politica-de-privacidad/',
  terms_and_conditions: 'https://yumminn.com/condiciones-de-uso-usuarios/',
};

export const DEFAULT_PROGRAM_ORDER_TITLE = {
  program_order_title: 'program_order_title',
};

export const DEFAULT_REGISTER_BUTTON_TITLES = {
  google: true, 
  apple: true, 
  facebook: false, 
  email: true
}

export const DEFAULT_TICKET_COPIES = {
  choose_your_ticket: 'choose your ticket',
  event_code: 'event_code',
  see_tickets: 'see_tickets',
  with_your_ticket_you_obtain: "with_your_ticket_you_obtain",
  you_have_x_tickets: "you_have_x_tickets"
};