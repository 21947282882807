import styled from "styled-components";

type smallButtonProps = {
  bgColor?: string
  textColor?: string
}

export const SmallButton = styled.button<smallButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  font-family: ${({theme}) => theme.typography};
  font-size: 14px;
  line-height: 1;
  text-transform: capitalize;
  color: ${({theme, textColor}) => textColor ? textColor : theme.text};
  background-color: ${({bgColor}) =>  bgColor ? bgColor : "transparent"};
  box-shadow: ${({theme}) => theme.shadow_xs};
  border: 2px solid ${({theme}) => theme.separator_color};
  border-radius: ${({theme}) => theme.radius_round};
  padding: 6px 12px;
  font-weight: 600;
`;