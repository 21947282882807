import React from "react";
import styled from "styled-components";
import { CloseButton } from "../../modal/ModalContainers";
import { Icon, IconType } from "../../../elements";
import { PopupType } from "../../../context/popups.enum";

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${({theme}) => theme.backdrop_color};
`;
const ModalContainer = styled.div<ModalContainerProps>`
  width: 100%;
  height: ${({ height }) => height};
  background-color: white;
  border-radius: ${({theme}) => `${theme.radius_xl} ${theme.radius_xl} 0 0`};
  position: absolute;
  overflow: hidden;
  top: ${({response}) =>response === 'error' ? "60%" : "30%"};
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  color: ${({theme}) => theme.text};
  padding: 24px 24px 0;
  position: relative;
  z-index: 1;
`;

type ModalContainerProps = {
  height: any;
  response: string;
}

const ZeroSixModal = ({ handleClose, children, isOpen, response }: props) => {
  return (
    <div>
      {isOpen && (
        <Overlay>
          <ModalContainer response={response} height={response != "" ? '40%': '75%'}>
          <ModalHeader>
            <CloseButton
                isScrollingDown={false}
                popup={PopupType.None}
                onClick={handleClose}
            >
                <Icon type={IconType.Close}/>
            </CloseButton>
            </ModalHeader>
            {children}
          </ModalContainer>
        </Overlay>
      )}
    </div>
  );
};

type props={
    handleClose: any,
    children: any,
    isOpen: any,
    response: string
  
  }
export default ZeroSixModal;
