import styled from "styled-components";
import { PopupType } from "../../context/popups.enum";

const MODALS_WITH_PHOTO = [
  PopupType.Product,
  PopupType.Ticket,
]

type ModalHeaderProps = {
  popup: PopupType
  isScrollingDown: boolean
}

const ModalHeader = styled.div<ModalHeaderProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  color: ${({theme}) => theme.text};
  padding: 24px 24px 0;
  position: ${({popup}) => MODALS_WITH_PHOTO.includes(popup) ? 'sticky' : 'relative'};
  z-index: ${({popup}) => MODALS_WITH_PHOTO.includes(popup) ? 10 : 1};
  top: ${({popup}) => MODALS_WITH_PHOTO.includes(popup) ? '0' : ''};

  h2 {
    font-size: ${({theme}) => theme.font_lg};
    font-weight: 600;
    margin: 0;
    letter-spacing: -0.6px;
    text-align: center;
  }
`;

const BackButton = styled.button<ModalHeaderProps>`
  display: flex;
  color: ${({theme}) => theme.text};
  background-color: transparent;
  border: none;
  padding: 16px;
  margin: -16px;
  z-index: 2;
  position: relative;
  isolation: isolate;
  
  &:disabled {
    opacity: 0;
    pointer-events: none;
  }

  &:focus {
    outline: none;
  }
  
  &::before {
    content: '';
    position: absolute;
    inset: 8px;
    background-color: ${({popup, theme}) => MODALS_WITH_PHOTO.includes(popup) ? theme.background : 'transparent'};
    border-radius: ${({theme}) => theme.radius_round};
    z-index: -1;
  }
`;

const CloseButton = styled(BackButton)`
  justify-self: flex-end;

  &:first-child {
    margin-left: auto;
  }
`;


const YumminnHeaderContainer = styled.div<Header>`
  display: flex;
  justify-content: ${({justifyEnd}) => justifyEnd ? 'flex-end' : 'flex-start'};
  width: 100%;
  margin: 0 -32px;
  z-index: -1;
`;

type Header = {
  justifyEnd: boolean;
}

export {
  ModalHeader,
  BackButton,
  CloseButton,
  YumminnHeaderContainer,
}