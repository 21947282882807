import React, { useEffect, useState } from 'react';
import coin from '../../assets/images/redesign/coin_points.svg'
import { AnimatedPoint } from './PointsBadgeContainers';
import point from '../../assets/images/redesign/points.svg'
import { Badge } from '../';
import { useTheme } from 'styled-components';

type Props = {
  points: number,
  granted?: boolean,
  text?: string,
}

export const PointsBadgeComponent = ({points, granted, text}: Props) => {
  const theme: any = useTheme();
  const [currentPoints, setPoints] = useState(points)
  
  const animatedPoints = []
  
  for (let i = 0; i < Math.min(points, 5); i++) {
    animatedPoints.push(<AnimatedPoint key={'point' + i} src={point} delay={0.25 * i}/>)
  }

  useEffect(() => {
    if (granted) {
      const ms = points >= 5 ? 1250 / points : 250
      const interval = setInterval(() => {
        setPoints(p => {
          p - 1 < 1 && clearInterval(interval);
          return p - 1;
        });
      }, ms);
    }
  }, [granted])
  
  return (
    <Badge color={theme.yumminn}>
      <img src={coin}/>
      <span>{currentPoints ? '+ ' + currentPoints : '  ✔'}</span>
      {text && currentPoints && <p>{' ' + text}</p>}
      {granted && animatedPoints}
    </Badge>
  )
};