import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppContext } from '../../context/AppContext';
import { Icon, IconType } from '../icons';
import { SplashScreenContainer } from './SplashScreenContainers';
import { ResponsiveLogo } from '../responsive-logo';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { GB_FEATS } from '../../growthbook';

type Props = {
  logo?: string
};

export const SplashScreenComponent = ({logo}: Props) => {
  const { state: { restaurantCustomization: { logoWidth } } } = useContext(AppContext);

  const showYumminnLogo = useFeatureIsOn(GB_FEATS.SHOW_YUMMINN_LOGO);

  const [ratio, setRatio] = useState(0);
  const logoRef = useRef<HTMLImageElement | null>(null);
  const [isShown, setIsShown] = useState(true);
  const location = useLocation();

  const getRatio = () => {
    const logo = logoRef.current as HTMLImageElement;
    const logoHeight = logo?.naturalHeight;
    const logoWidth = logo?.naturalWidth;
    setRatio(logoWidth/logoHeight);
  };

  useEffect(() => {
    let isMounted = true;
    const state = location.state as { from: string }
    const from = state ? state.from : undefined

    setIsShown(!from)
    
    if (!from) {
      const timerId = setTimeout(() => {
        if (isMounted) {
          setIsShown(false);
        }
      }, 2300);

      return () => {
        // Cleanup function to clear the timer if the component unmounts
        isMounted = false;
        clearTimeout(timerId);
      };
    }

  }, [])
  
  if (!isShown) return <></>

  return (
    <SplashScreenContainer ratio={ratio} logoWidth={logoWidth}>
      {logo &&
        <img src={logo} ref={logoRef} onLoad={getRatio} alt='logo' className='logo'/>
      }
      {!logo && showYumminnLogo &&
        <ResponsiveLogo size={innerWidth/5} className='logo yumminn'/>
      }
      {showYumminnLogo &&
        <footer>
          <span>powered by</span>
          <Icon type={IconType.Logo}/>
        </footer>
      }
    </SplashScreenContainer>
  );
};
