import React, { useEffect } from 'react';
import withRouter from './withRouter';
import { useLocation } from 'react-router';

type props = {
    children?: any;
}

function ScrollToTop({ children }: props) {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);


  return <>{children}</>;
}

export default withRouter(ScrollToTop);
