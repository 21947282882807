import React, { useContext } from 'react';
import { t } from 'i18next';
import { Icon, IconType } from '../icons';
import { BadgeContainer } from './TagContainers';
import { TAG_LIST } from '../../shared/constants/tags';
import { Tags } from '../../context/app';
import { AppContext } from '../../context/AppContext';
import { LIGHT_THEMES } from '../../shared/themes/themeGroups';

type Props = {
  value: Tags;
  size?: number;
  showLabel?: boolean;
};

export const TagBadgeComponent = ({value, size = 16, showLabel = false}: Props) => {
  const { state: { restaurantCustomization : { theme } } } = useContext(AppContext);

  const tag = TAG_LIST.find(tag => tag.value === value);

  if (!tag) return <></>;

  const { label, color } = tag;
  
  const themeColor = LIGHT_THEMES.includes(theme) ? color : 'currentColor';

  return (
    <BadgeContainer>
      <Icon type={IconType[label]} colorIcon={themeColor} size={size}/>
      {showLabel &&
        <span>
          {t(label)}
        </span>
      }
    </BadgeContainer>
  );
};