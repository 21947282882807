import { t } from 'i18next';
import React, { useEffect, useContext, useState } from 'react'
import { AppContext } from '../../context/AppContext';
import { PriceBadge } from '../../pages/checkout/components/MyTotal';
import { calculateEuros, calculateCents } from '../../pages/checkout/functions/calculatePrices';
import { LoyaltyBanner } from '../../pages/loyalty/loyalty-banner';
import { BASTARD_COFFEE_XUQUER, BASTARD_COFFEE_RUZAFA, TRESPUNTOCERO, URBAN_POKE_BAR } from '../../shared/constants/restaurantIds';
import { DetailsContainer, TotalCredits, ExtraCredits, TotalAmountPurchase } from './PaymentDetailContainers'
import { ZeroSixVoucherSelector } from '../../pages/zerosix/zerosix-voucher-selector';
import { useTheme } from 'styled-components';
import { Request } from '../../api/discounts/Discounts.api';
import { toast } from 'react-toastify';
import { dispatchCodeCoupon } from '../../shared/functions';
import { postError } from '../../shared/utils/postError';
import { CreditBanner } from '../../pages/credit/credits-banner';
import { Trans } from 'react-i18next';
import { Icon, IconType } from '../icons';
import { BadgeBonus } from '../../pages/wallet/WalletContainers';
import { DashedSeparator } from '../../pages/payment-successful/PaymentSuccessfulContainers';
import { CodeCouponInput } from './CodeCouponInput';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { GB_FEATS } from '../../growthbook';

type Props = {
  setCreditApplied: React.Dispatch<React.SetStateAction<boolean>>
  setDiscountApplied: React.Dispatch<React.SetStateAction<boolean>>
  bizumDiscount?: boolean
}

const tipType = {
  custom: "custom",
  good: "5%",
  "very good": "10%",
  amazing: "15%",
  brilliant: "20%",
  default: "0%",
}

export const PaymentDetailComponent = ({ setCreditApplied, setDiscountApplied, bizumDiscount }: Props) => {
  const restaurantId = Number(sessionStorage.getItem('restaurantId'));
  const {
    state: {
      restaurant: {
        bizumPromotionCents,
        has_code_coupons,
        always_allow_code_coupon
      },
      checkout: {
        myTotal,
        clientCommission,
        loyaltyDiscountCents,
        generalDiscount,
        generalDiscountCents,
        productDiscountCents,
        codeCoupon,
        creditCents,
        credit,
        codeCouponCents,
        paymentMethod,
        zeroSixInfo,
        zeroSixSelectedVoucher,
        deliveryFeeCents,
        paymentSplitType,
        tip: {
          selected,
          amount,
        },
      },
      wallet: {
        extraCredits,
        balanceCredit, 
        bonusAmount, 
        bonusType
        }
      },
    dispatch,
  } = useContext(AppContext);
  
  const theme: any = useTheme();
  
  const showZerosix = useFeatureIsOn(GB_FEATS.SHOW_ZEROSIX);
  
  const isRechargePayment = paymentSplitType === 'recharge'
  const serviceCommision = Math.floor(((myTotal) * clientCommission) / 100);
  const bizumDiscountCents = paymentMethod === 'bizumpay' && (myTotal - amount) >= 1500 ? bizumPromotionCents : 0;
  const filteredZeroSixVouchers = zeroSixInfo?.vouchers?.filter(voucher => {
    return voucher.min_amount * 100 <= myTotal - amount && (voucher.is_percentage || (!voucher.is_percentage && myTotal - amount >= voucher.value * 100));
  });
  console.log(zeroSixInfo)
  const zeroSixDiscountCents = zeroSixSelectedVoucher.code != "" ? (!zeroSixSelectedVoucher.is_percentage ? zeroSixSelectedVoucher.value * 100 :  Math.round((((myTotal - amount) *  zeroSixSelectedVoucher.value) / 100))) : 0 
  const discounts = generalDiscountCents + productDiscountCents + codeCouponCents + creditCents + loyaltyDiscountCents + bizumDiscountCents + zeroSixDiscountCents;
  const subtotal = myTotal - amount;
  const amountMinusDiscounts = Math.max(subtotal - generalDiscountCents - productDiscountCents - codeCouponCents, 0);
  const creditUsed = Math.min(creditCents, amountMinusDiscounts);
  const total = Math.max(subtotal + serviceCommision - (!isRechargePayment ? discounts : 0), 0) + deliveryFeeCents + amount;

  const amountMinusDiscountsAndCredit = Math.max(subtotal - generalDiscountCents - productDiscountCents - codeCouponCents - creditCents, 0);
  const amountMinusDiscountsAndCreditAndLoyalty = Math.max(subtotal - generalDiscountCents - productDiscountCents -  codeCouponCents - creditCents - loyaltyDiscountCents, 0);
  const loyaltyAfterDiscounts = Math.min(loyaltyDiscountCents, amountMinusDiscountsAndCredit)
  const disabledCouponField = amountMinusDiscountsAndCreditAndLoyalty === 0 && codeCouponCents === 0

  const [redeemingCodeCoupon, setRedeemingCodeCoupon] = useState(false);
  const [codeCouponText, setCodeCouponText] = useState('');
  const [codeCouponError, setCodeCouponError] = useState('');
  const [isAnyCouponValid, setIsAnyCouponValid] = useState(false);
  const finalExtraCredits = extraCredits ? Number(calculateCents(extraCredits)) : 0
  const newCreditBalance = balanceCredit + subtotal + finalExtraCredits

  const handleCouponChange: React.ChangeEventHandler = (event) => {
    setCodeCouponText((event.target as HTMLInputElement).value);
    setCodeCouponError('');
  };

  const codeCouponApplyClicked = (codeCouponParam?: string) => {
    try {
      const totalOrder = myTotal - amount
      setRedeemingCodeCoupon(true);
      Request.redeemCodeCoupon(restaurantId, codeCouponText ? codeCouponText : codeCouponParam as string, totalOrder)
      .then(response => {
        dispatchCodeCoupon(response.data, myTotal - amount - generalDiscountCents - productDiscountCents, dispatch);
      })
      .catch(err => {
        if(err?.response?.data && err?.response?.data.includes('only valid for clients')){
          setCodeCouponError(t('coupon invalid for this client'));
          toast.error(t('coupon invalid for this client'), {
            theme: 'colored',
          });
        } else {
          setCodeCouponError(t('Invalid code. Try again'));
          toast.error(t('Invalid code. Try again'), {
            theme: 'colored',
          });
        }
        postError(err)
        console.log(`codeCouponApplyClicked ~ Request.redeemCodeCoupon err: ${err.toString()}`);
      })
      .finally(() => setRedeemingCodeCoupon(false));
    } catch(e: any) {
      postError(e)
      console.log(`codeCouponApplyClicked exception: ${e.toString()}`);
    }
  }

  const codeCouponDeleteClicked = () => {
    dispatch({
        type: 'UPDATE_CHECKOUT',
        payload: {
          codeCoupon: null,
          codeCouponCents: 0
        }
    });
    setCodeCouponText('');
  }

  const discountTypeToSymbol: any = {
    "amount": "€",
    "percentage": "%"
  };


  const codeCouponCheckValidity = async(isMounted: boolean) => {
    try {
      const totalOrder = myTotal - amount
      Request.checkCodeCouponValidity(restaurantId, totalOrder)
      .then((response: any) => {
        if(isMounted){
          setIsAnyCouponValid(response.data.valid)
          if(response.data.preassigned && response.data.valid && !disabledCouponField && !isRechargePayment){
            setCodeCouponText(response.data.code)
            codeCouponApplyClicked(response.data.code)
          }
        }
      })
      .catch((err: any) => {
        console.log(`codeCouponCheckValidity ~ Request.checkCodeCouponValidity err: ${err.toString()}`);
      })
      .finally(() => isMounted && setRedeemingCodeCoupon(false));
    } catch(e: any) {
      postError(e)
      console.log(`codeCouponCheckValidity exception: ${e.toString()}`);
    }
  }

  useEffect(() => {
    let isMounted = true;

    const cleanup = () => {
      isMounted = false; 
    };

    if(isMounted){
      if(has_code_coupons){
        codeCouponCheckValidity(isMounted)
      }

      if(has_code_coupons && codeCouponCents > 0){
        setCodeCouponText(codeCoupon.code)
      }
    }

    return cleanup
  }, [])


  if(isRechargePayment){
    return(
      <DetailsContainer>
        <p>
          <span>{t('credits_to_pay')}:</span>
          <TotalAmountPurchase>{`${calculateEuros((subtotal).toString())}€`}</TotalAmountPurchase>
        </p>
        <p>
          <span>{t('credits_you_will_obtain')}:</span>
          <span style={{fontSize: "18px", fontWeight: 700}}>{`${calculateEuros(subtotal + finalExtraCredits).toString()}€`}</span>
        </p>
        {extraCredits > 0 && 
          <ExtraCredits>
            <Icon type={IconType.TabArrow} size={10}/>
            <i>
              <b style={{marginRight: "5px"}}>+{`${calculateEuros(finalExtraCredits).toString()}€`}</b>
              {t('of extra credits')}
            </i>
            <BadgeBonus style={{top: '0px'}}>
              {bonusType === 'percentage' && bonusAmount > 0 && <span>+{Number(bonusAmount)}%</span>}
            </BadgeBonus>
          </ExtraCredits>
        }
        <DashedSeparator/>
        <i style={{margin: "10px 0"}}>{t('when topping up your total wallet balance will be')}:</i>
        <TotalCredits>
          <b style={{fontSize: "20px"}}>{calculateEuros(newCreditBalance)}€</b> 
          <div style={{marginLeft: "4px"}}>{t('of')}</div>              
          <Trans i18nKey='total_credits'>créditos <b>totales</b></Trans>
        </TotalCredits>
      </DetailsContainer>
    )
  }

  return (
    <DetailsContainer>
      <p>
        <span>{t('subtotal')}</span>
        <span>{`${calculateEuros((subtotal).toString())}€`}</span>
      </p>
      {![URBAN_POKE_BAR, TRESPUNTOCERO, BASTARD_COFFEE_XUQUER, BASTARD_COFFEE_RUZAFA].includes(restaurantId) &&
        <p>
          <span>{t('tip') + ` (${t(tipType?.[(selected as keyof typeof tipType)] || tipType.default)})`}</span>
          <span>{calculateEuros(amount) + '€'}</span>
        </p>
      }
      {generalDiscountCents > 0 &&
        <p>
          <span className='discount'>{generalDiscount?.payment_details_text + " (-" + (generalDiscount?.discount_type == "percentage" ? generalDiscount?.value : generalDiscount?.value) + discountTypeToSymbol[generalDiscount!.discount_type] + ")"}</span>
          <span className='discount'>{`- ${calculateEuros(generalDiscountCents)}€`}</span>
        </p>
      }
      {productDiscountCents > 0 &&
        <p>
          <span className='discount'>{t('product_discount')}</span>
          <span className='discount'>{`- ${calculateEuros(productDiscountCents)}€`}</span>
        </p>
      }
      {codeCouponCents > 0 &&
        <p>
          <span className='discount'>{codeCoupon?.payment_details_text + " (-" + codeCoupon?.value + discountTypeToSymbol[codeCoupon?.discount_type] + ")"}</span>
          <span className='discount'>{`- ${calculateEuros(codeCouponCents)}€`}</span>
        </p>
      }
      {creditCents > 0 && credit &&
        <p>
          <span className='discount'>{t('credits')}</span>
          <span className='discount'>{`- ${calculateEuros(creditUsed)}€`}</span>
        </p>
      }
      {serviceCommision > 0 &&
        <p>
          <span className='discount'>{t('service fee')}</span>
          <span className='discount'>{`${calculateEuros(serviceCommision)}€`}</span>
        </p>
      }
      {deliveryFeeCents > 0 &&
        <p>
          <span className='discount'>{t('delivery_fee')}</span>
          <span className='discount'>{`${calculateEuros(deliveryFeeCents)}€`}</span>
        </p>
      }
      {loyaltyAfterDiscounts > 0 &&
        <p>
          <span className='discount'>{t('loyalty discount')}</span>
          <span className='discount'>{`${"-" + calculateEuros(loyaltyAfterDiscounts)}€`}</span>
        </p>
      }
      {bizumDiscount && paymentMethod === 'bizumpay' && (myTotal - amount) >= 1500  &&
        <p style={{color: theme.primary}}>
          <span>{t('bizum discount applied')}: </span>
          <span className='discount' style={{color: theme.primary }}>{`-${calculateEuros((bizumPromotionCents).toString())}€`}</span>
        </p>
      }
      {showZerosix && zeroSixDiscountCents > 0  &&
        <p style={{color: '#ED2C03'}}>
          <span>{t('zerosix discount applied')}: </span>
          <span style={{color: '#ED2C03'}}>{`-${calculateEuros((zeroSixDiscountCents).toString())}€`}</span>
        </p>
      }
      <LoyaltyBanner
        setDiscountApplied={setDiscountApplied}
        amountMinusDiscountsAndCredit={amountMinusDiscountsAndCredit}
      />
      <CreditBanner
        setCreditApplied={setCreditApplied}
        creditUsed={creditUsed}
        amountMinusDiscountsAndCredit={amountMinusDiscountsAndCreditAndLoyalty}
      />
      {((has_code_coupons && isAnyCouponValid) || (always_allow_code_coupon)) &&
        <CodeCouponInput
          codeCouponCents={codeCouponCents}
          codeCouponDeleteClicked={codeCouponDeleteClicked}
          codeCouponText={codeCouponText}
          handleCouponChange={handleCouponChange}
          codeCouponError={codeCouponError}
          redeemingCodeCoupon={redeemingCodeCoupon}
          disabledCouponField={disabledCouponField}
          codeCouponApplyClicked={codeCouponApplyClicked}
        />
      }
      {showZerosix && filteredZeroSixVouchers?.length > 0 && 
        <ZeroSixVoucherSelector
          vouchers_length={filteredZeroSixVouchers.length}
        />
      }
      <b>
        <span>{t('payment pending')}</span> 
        <PriceBadge type='total'>
          {`${calculateEuros((total).toString())}€`}
        </PriceBadge>
      </b>
    </DetailsContainer>
  );
};