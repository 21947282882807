import React from 'react';
import { RestaurantCardContainer, RestaurantCardContainerVouchers, RestaurantCardImg, VocuhersContainer, VoucherInfoContainer, ZeroSixCoinImg } from '../LoyaltyContainers';
import noLogo from "../../../assets/images/noLogo.png";
import { Icon, IconType } from '../../../elements/icons';
import zerosixCoin from "../../../assets/images/zerosix.svg";

export const ZeroSixRestaurantCard = ({card}: Props) => {
  const [showVouchers, setShowVouchers] = React.useState<boolean>(false);
  const {restaurant, vouchers, points_balance} = card
  console.log(vouchers)

  const handleClick = () => {
    setShowVouchers(!showVouchers)
  };

  return (
    <div>
    <RestaurantCardContainer isRedeemable={true} onClick={handleClick}>
      <div style={{width: '100%', height: '80px', display: 'flex'}}>
      <div style={{width: '25%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <RestaurantCardImg src={restaurant?.logo || noLogo} alt={restaurant?.name}/>
      </div>
      <div style={{width: '50%', padding: '5px 10px'}}>
        <h3>{restaurant?.name}</h3>
        <VoucherInfoContainer>
          <h4>{vouchers.length}</h4>
          <h5> Vouchers</h5>
        </VoucherInfoContainer>
      </div>
      <div style={{width: '25%', padding: '5px 10px', display: 'flex', flexDirection: 'row'}}>
        <ZeroSixCoinImg src={zerosixCoin}/>
        <div style={{flexDirection: 'column', display: 'flex'}}>
        <h6 style={{fontSize: '25px'}}>{points_balance}</h6>
        <h6 style={{marginTop: '-5px', fontSize: '10px'}}>Puntos</h6>
        </div>
      </div>
      </div>
        {vouchers.length > 0 && (!showVouchers ?
                <Icon type={IconType.ArrowDown}/>
                :
                <Icon type={IconType.ArrowUp}/>
              )}
    </RestaurantCardContainer>
    {showVouchers && vouchers.length > 0 &&
    <VocuhersContainer>
       {vouchers.map((item: any, index: number) => {
        return (
          <RestaurantCardContainerVouchers key={index}>
            <div style={{display: 'flex', flex: 1, padding: '0 5px', justifyContent: 'space-between', flexDirection: 'row'}}>
            <div style={{display: 'flex', flexDirection: 'column'}}>
              <h1 style={{fontSize: '14px', color: '#ED2C03', fontWeight: 600}}>{item.name}</h1>
              <h1 style={{fontSize: '12px', fontWeight: 1}}>Compra mínima {item.minimum_amount}€</h1>
              </div>
              <div style={{display: 'flex', flexDirection: 'column'}}>
              <div style={{backgroundColor: '#ED2C03', alignItems: 'center', padding: '0 15px', borderRadius: '10px', justifyContent: 'center', display: 'flex'}}>
                <h1 style={{fontSize: '14px', color: '#FFF', fontWeight: 600}}>{item.code}</h1>
              </div>
              <h1 style={{fontSize: '11px', fontWeight: 1}}>Exp: {item.expiration.replace(/-/g, '.')}</h1>
              </div>
            </div>
          </RestaurantCardContainerVouchers>
        )
       })}
       </VocuhersContainer>}
    </div>
  );
};

type Voucher = {
  name: any;
  code: number;
  value: number;
  unit: number;
  expiration: number;
  minimum_amount: number;
}

type CardsData = {
  restaurant: any;
  points_balance: number;
  total_points: number;
  vouchers: Voucher[];
}

type Props = {
  card: CardsData
}