import React, { useEffect, useRef } from 'react';
import { Overlay, ToggletipChildren, ToggletipContainer, ToggletipContent, ToggletipWrapper } from './ToggletipContainers';

type Props = {
  content: any
  children?: React.ReactNode
  isVisible: boolean
  overlay?: boolean
  position?: 'top' | 'bottom' | 'right' | 'left' 
  alignment?: 'start' | 'center' | 'end'  
  background?: string
  onClose: () => void
  from?:string
};

export const ToggletipComponent = ({
  content,
  children,
  isVisible,
  overlay,
  position = 'top',
  alignment = 'center',
  background,
  onClose,
  from
}: Props) => {
  const toggletipRef = useRef<HTMLDivElement>(null)
  const contentRef = useRef<HTMLDivElement>(null)
  const categoriesTooltip =  content && (content.toString().includes('categories') || content?.props?.name == 'gps')
  const gpsTooltip = content?.props?.name == 'gps'
  const path = location.pathname;

  useEffect(() => {
    if (isVisible && !categoriesTooltip) {
      // fix overflow if necessary
      
      setTimeout(() => {
        const contentRect = contentRef.current?.getBoundingClientRect() as DOMRect;
        const overflowTop = contentRect && Math.max(-1 * contentRect.top, 0);
        const overflowLft = contentRect && Math.max(-1 * contentRect.left, 0);
        const overflowBtm = contentRect && Math.max(contentRect.bottom - innerHeight, 0);
        const overflowRgt = contentRect && Math.max(contentRect.right - innerWidth, 0);
        
        if (['top', 'bottom'].includes(position) && (overflowRgt || overflowLft)) {
          overflowRgt && ((contentRef.current as HTMLDivElement).style.translate = `-${overflowRgt + 8}px 0`)
          overflowLft && ((contentRef.current as HTMLDivElement).style.translate = `${overflowLft + 8}px 0`)
        }

        if (['right', 'left'].includes(position) && (overflowTop || overflowBtm)) {
          overflowBtm && ((contentRef.current as HTMLDivElement).style.translate = `-${overflowBtm + 8}px 0`)
          overflowTop && ((contentRef.current as HTMLDivElement).style.translate = `${overflowTop + 8}px 0`)
        }
      }, 200)
    }
  
    const handleTouch = (event: TouchEvent) => {
      const childNodes = toggletipRef.current?.childNodes as NodeList;
      const isPopup = childNodes ? Array.from(childNodes).some(node => node.contains(event.target as Node)) : null;

      if (isPopup) return;
      
      onClose();
    };

    isVisible
      ? window.addEventListener('touchstart', handleTouch)
      : window.removeEventListener('touchstart', handleTouch);
    

    return () => window.removeEventListener('touchstart', handleTouch);
  }, [isVisible])

  const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  return (
    <ToggletipWrapper ref={toggletipRef} isVisible={isVisible} toolTipcontent={categoriesTooltip as boolean}>
      <ToggletipContainer position={position} background={background} isVisible={isVisible} toolTipcontent={gpsTooltip ? 'gps' as string : categoriesTooltip as boolean}>
        <ToggletipContent ref={contentRef} position={position} alignment={alignment} background={background}>
            {content}
        </ToggletipContent>
      </ToggletipContainer>
      {isVisible && overlay && path !== '/payment-successful' && <Overlay onClick={(e) => handleOverlayClick(e)} />}
      <ToggletipChildren isVisible={isVisible} overlay={overlay} toolTipcontent={categoriesTooltip as boolean}>
        {children}
      </ToggletipChildren>
    </ToggletipWrapper>
  );
};
