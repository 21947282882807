import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PageHeader } from '../../elements';
import ZeroSixLoyaltyCards from '../zerosix';


export const ZeroSixCards = () => {
  const navigate = useNavigate();

  return (
    <>
      <PageHeader text='ZeroSix' onGoBack={() => navigate('/profile')}/>
      <ZeroSixLoyaltyCards />
    </>
  )
};