import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate } from "react-router";
import { UseQRUUID } from "../../api/restaurants/Restaurants.api";
import { useParams } from 'react-router-dom'
import { Loading } from "../loading";
import { useGrowthBook } from "@growthbook/growthbook-react";
import { AppContext } from "../../context/AppContext";
import { eventTrack } from "../../../useGaTracker";
import { postError } from "../../shared/utils/postError";
import { HomeOuterContainer } from "../../pages/home/HomeOuterContainer";
import { TableNotFound } from "../../pages/not-found";

export const QrUuidReaderComponent = () => {
  const navigate = useNavigate();
  const growthbook = useGrowthBook();

  const {
    dispatch,
  } = useContext(AppContext);
  
  const { qr_uuid } = useParams<{ qr_uuid: string }>();
  const [isLoading, setIsLoading] = useState(true);
  const isMounted = useRef(true);
  const urlParams = new URLSearchParams(window.location.search);
  const userIdBumerang = urlParams.get('userId')
  const yumiwiClientUuid= urlParams.get('yumiwi_client_uuid')
  if (yumiwiClientUuid) {
    localStorage.setItem('yumiwiClientUuid', yumiwiClientUuid)
  }
  let id = 0
  let table = 0
  let uuid = ''
  
  useEffect(() => {
    if (isMounted.current) {

      eventTrack("00_Escaneo", "00_Escaneo", "00_Escaneo");

      UseQRUUID(qr_uuid!)
      .then((response: any) => {
        try {
          id = response?.data?.results[0]?.sector?.restaurant;
          uuid = response?.data?.results[0]?.restaurant_uuid;
          table = response?.data?.results[0]?.number;

          if(id && table){
            sessionStorage.setItem('qrUuid', qr_uuid!);
            sessionStorage.setItem('restaurantId', id.toString());
            sessionStorage.setItem('restaurantUuid', uuid);
            sessionStorage.setItem('tableNumber', table.toString());
      
            dispatch({
              type: 'UPDATE_RESTAURANT_ID',
              payload: Number(id),
            });
      
            dispatch({
              type: 'UPDATE_TABLE_NUMBER',
              payload: String(table),
            });
      
            growthbook?.refreshFeatures();
            
            const checkoutUrl = '/checkout';
            navigate(checkoutUrl, {state: {search: userIdBumerang ? `?userId=${userIdBumerang}` : undefined}});
          }
        } catch (e: any) {
          postError(e);
          console.log("Error fetching QR UUID:", e);
        }
      }).catch((err: any) => postError(err)
      ).finally(() => setIsLoading(false));
    }
    return () => {
      isMounted.current = false;
    };
  }, []);

  if (isLoading) {
    return <Loading parentComponent='QrUuidReaderComponent'/>;
  }

  return (
    <HomeOuterContainer>
      <TableNotFound qrUuid={qr_uuid!} restaurantId={id} tableNumber={table} parentComponent='QrUuidReaderComponent'/>
    </HomeOuterContainer>
  );
};
