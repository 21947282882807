import { t } from 'i18next';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSwipe } from '../../shared/utils/useSwipe';
import { Label, Slider, TabContainer } from './TabSelectorContainers';

type Props = {
  name: string;
  value: any;
  options: string[] | { value: any, label: string }[];
  disabled?: boolean;
  color?: string;
  variant?: 'pill' | 'switch' | 'line'
  className?: string;
  onChange: React.Dispatch<React.SetStateAction<any>>;
};

export const TabSelectorComponent = ({name, value, options, disabled, color, variant = 'pill', className = '', onChange}: Props) => {
  const tabsRef = useRef<Array<HTMLLabelElement | null>>(new Array(options.length));

  const [{valueIndex, sliderWidth, sliderPosition}, setValues] = useState({ valueIndex: 0, sliderWidth: 0, sliderPosition: 0 });

  const objOptions = useMemo(() => options.map(option => {
    if (typeof option === 'string') return { value: option, label: option };
    return option;
  }), [options]);

  useEffect(() => {
    const valueIndex = objOptions.findIndex(option => option.value === value);
    const label = tabsRef.current[valueIndex];
    const sliderWidth = label?.clientWidth || 0;
    const sliderPosition = label?.offsetLeft || 0;

    if (label) {
      const parentElement = label.parentElement;
      const isInViewport = parentElement && (() => {
        const rect = parentElement.getBoundingClientRect();
        return rect.top >= 0 && rect.bottom <= window.innerHeight;
      })();

      if (isInViewport) {
        const parentRect = parentElement.getBoundingClientRect();
        const labelRect = label.getBoundingClientRect();

        const isFullyVisible =
          labelRect.left >= parentRect.left &&
          labelRect.right <= parentRect.right;

        if (!isFullyVisible) {
          parentElement.scrollLeft = label.offsetLeft - parentRect.width / 2 + label.clientWidth / 2;
        }
      }
    }
    setValues({ valueIndex, sliderWidth, sliderPosition });
  }, [value, objOptions]);

  const onSwipedLeft = () => {
    valueIndex > 0 && onChange(objOptions[valueIndex - 1].value)
  };

  const onSwipedRight = () => {
    valueIndex < objOptions.length - 1 && onChange(objOptions[valueIndex + 1].value)
  };

  const swipeHandlers = useSwipe({onSwipedLeft, onSwipedRight});

  return (
    <TabContainer
      {...(variant === 'switch' ? swipeHandlers : {})}
      disabled={disabled}
      variant={variant}
      className={className}
    >
      <Slider variant={variant} color={color} style={{width: sliderWidth + 'px', left: sliderPosition + 'px'}}/>
      {objOptions.map((option, index) => (
        <Label key={'option-' + option.label} variant={variant} ref={tab => tabsRef.current[index] = tab}>
          {option.label === 'credit' ? t('credits') : t(option.label)}
          <input
            type="radio"
            name={name}
            value={option.value}
            checked={value === option.value}
            onChange={() => onChange(option.value)}
            disabled={disabled}
          />
        </Label>
      ))}
    </TabContainer>
  );
};