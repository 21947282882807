import React from "react";
import {Formik} from "formik";
import { useRegister } from "../../shared/utils/useRegister";
import { t } from "i18next";
import { Consents } from "../../elements/register/Consents";
import { Button, TextInput, LoginButtons } from "../../elements";
import { FormContainer, InputWrapper } from "./RegisterContainers";
import { ButtonType, ButtonVariant } from "../../elements/buttons/enums";
import { registerValidation, termsAndConditionsRequired } from "./validations";
import { toast } from "react-toastify";

const path = window.location.pathname.replace('/', '');

type Props = {
  handleLinkClick: React.MouseEventHandler
  showFormByDefault?: boolean
  from?: string
  defaultValues?: Partial<{
    email: string
    password: string
    confirm_password: string
    lopd: boolean
    news: boolean
    from: string
  }>
};

export const RegisterForm = ({handleLinkClick, from = path, showFormByDefault = false, defaultValues = {}}: Props) => {
  const { onRegisterSubmit, isLoading } = useRegister({});
  const path = location.pathname

  const initialValues = {
    email: "",
    password: "",
    confirm_password: "",
    lopd: false,
    news: false,
    type: "internal",
    from: from,
    ...defaultValues,
  };
  
  const toastError = () => toast.error(termsAndConditionsRequired, { theme: 'colored' });
  const addmarginbottom = path === '/register' ? true : false

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={registerValidation}
      onSubmit={!isLoading ? onRegisterSubmit : () => null}
      validateOnChange={false}
    >{({values, handleChange, errors, submitForm}) => (
      <FormContainer addmarginbottom={addmarginbottom}>
        <Consents values={values} handleChange={handleChange}/>
        <LoginButtons
          from={from}
          lopd={values.lopd}
          news={values.news}
          bigButtons
          showFormByDefault={showFormByDefault}
          disabled={!values.lopd || isLoading}
          disabledMessage={termsAndConditionsRequired}
          mailForm={
            <InputWrapper>
              <TextInput
                name={'email'}
                title={'email'}
                placeholder={'email'}
                value={values.email}
                error={errors.email}
                onChange={handleChange}
              />
              <TextInput
                password
                name={'password'}
                title={'password'}
                placeholder={'password'}
                value={values.password}
                error={errors.password}
                onChange={handleChange}
              />
              <TextInput
                password
                name={'confirm_password'}
                title={'confirm_password'}
                placeholder={'confirm_password'}
                value={values.confirm_password}
                error={errors.confirm_password}
                onChange={handleChange}
              />
              <Button
                title={t('create account')}
                type={ButtonType.Submit}
                handleClick={() => values.lopd ? submitForm() : toastError()}
                loading={isLoading}
                $disabled={!values.lopd}
              />
            </InputWrapper>
          }
        />
        <Button variant={ButtonVariant.Link} title={t('already_have_account')} handleClick={handleLinkClick}/>
      </FormContainer>
    )}</Formik>
  );
};
