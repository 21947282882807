import { RequestProduct } from "../../../api/product/Product.api";
import { Modifier, OPCartProduct, OPDisplayProduct, ReadOnlyModifier } from "../../../context/app";
import { parseDecimalString } from "../../checkout/functions/calculatePrices";

export const createNewCartProduct = (product: OPDisplayProduct) : OPCartProduct => {
  const productDiscount = product.product_discount ? {...product.product_discount} : undefined;
  
  return {
    id: product.id,
    image: product.image,
    name: product.visible_name,
    allergens: product.allergens,
    tags: product.tags,
    tpv_id: product.tpv_id,
    unique_id: performance.now(),
    product_discount: productDiscount,
    base_unit_price_cents: getEffectiveTpvPriceCents(product),
    amount: 1,
    modifiers: [],
    dish_order: -1,
    comment: '',    
  };
};

export function getEffectiveTpvPriceCents(product: OPDisplayProduct): number {
  return product.price_cents !== 0 ? product.price_cents : product.tpv_price_cents;
}

export const calculateDiscountedPrice = (priceCents: number, discount: { value: number|string; type: string }) => {
  if (!discount) return priceCents;

  const { value, type } = discount;
  let discountCents = 0;

  if (type === 'percentage') {
    discountCents = Math.floor(priceCents * (value as number / 100));
  } else if (type === 'amount') {
    discountCents = value as number * 100; 
  }

  return priceCents - discountCents;
};

export const calculateDiscountCents = (priceCents: number, discount: { value: string|number; type: string }) => {
  if (!discount) return 0;

  const { value, type } = discount;
  let discountCents = 0;

  if (type === 'percentage') {
    discountCents = Math.floor(priceCents * (value as number / 100));
  } else if (type === 'amount') {
    discountCents = value as number * 100; 
  }

  return discountCents;
};

export const getTotalModsPrice = (modifiers: Modifier[]) => {
  if (!modifiers || !modifiers.length) return 0;

  return modifiers.reduce((total, mod) => {
    const price = mod.price_cents > 0 ? mod.price_cents : mod.tpv_price_cents
    total += price * mod.amount;

    if (!mod.modifiers || !mod.modifiers.length) return total;

    mod.modifiers.forEach((subMod: any)  => {
      const subModPrice = subMod.price_cents > 0 ? subMod.price_cents : subMod.tpv_price_cents
      total += subModPrice * subMod.amount * mod.amount;
    });

    return total;
  },0);
};

export const getTotalProductPrice = (product: OPCartProduct) => {
  return product.base_unit_price_cents + getTotalModsPrice(product.modifiers);
};

export const getTotalCartPrice = (products: OPCartProduct[]) => (
  products.reduce((total, product) => total += getTotalProductPrice(product) * product.amount, 0)
);

export const getProductDiscount = (product: OPCartProduct) => {
  if(product.product_discount){
    const unitPrice = product.base_unit_price_cents + getTotalModsPrice(product.modifiers);
    const discountCents = calculateDiscountCents(unitPrice, product.product_discount as { value: number; type: string; });
    return discountCents;
  } 
  return 0;
}

export const getTotalProductDiscount =  (products: OPCartProduct[]) => (
  products.reduce((total, product) => total += getProductDiscount(product) * product.amount, 0)
);

export const openProductPopupIfModifiers = (product:any,restaurantId:number, tableNumber:string, openProductPopup:any, addProduct:any=null, existingUnitsInCart:number|null=null, setLoadingFunction:any=null) => {
  if (product && !product.modifier_categories) {
    RequestProduct.getProductModifiers(restaurantId, tableNumber, product.id)
    .then((data: any) => {
      const modifier_categories = data?.data?.modifier_categories
      if ((modifier_categories && modifier_categories.length > 0) || product.choose_order) {
        product["modifier_categories"] = modifier_categories
        openProductPopup(product)
      } else {
        if (addProduct !== null) {
          addProduct(createNewCartProduct(product))
        } else {
          openProductPopup(product, existingUnitsInCart);
        }
      }
    })
    .catch(() => {
      if (addProduct !== null) {
        addProduct(createNewCartProduct(product))
      } else {
        openProductPopup(product);
      }
    }).finally(() =>   setLoadingFunction !== null && setLoadingFunction(false))
  } else {
    setLoadingFunction !== null && setLoadingFunction(false)
    openProductPopup(product);
  }
};

export const checkItemDetails = (item: OPDisplayProduct | ReadOnlyModifier) => {
  if (!item) return false;

  return (['description', 'allergens', 'tags', 'allergens_ids', 'tags_ids'] as Array<keyof typeof item>).some(
    field => !!item[field] && item[field].length > 0
  );
};

export const DECIMAL_NUTRITIONAL_FIELDS = [
  'proteins_string',
  'fats_string',
  'carbohydrates_string',
  'saturated_fats_string',
  'fiber_string',
  'sugars_string',
  'sodium_string',
];

const ALL_NUTRITIONAL_FIELDS = [
  ...DECIMAL_NUTRITIONAL_FIELDS,
  'ingredients',
  'serving_size_string',
]

export const checkNutritionalInfo = (item: OPDisplayProduct | ReadOnlyModifier) => {
  if (!item) return false;

  return (ALL_NUTRITIONAL_FIELDS as Array<keyof typeof item>).some(
    field => !!item[field] || item[field] === 0
  );
};

export const calculateCalories = (item: OPDisplayProduct | ReadOnlyModifier) => {
  if (
    !item ||
    (!item.serving_size_string && item.serving_size_string !== 0) ||
    (['fats_string', 'carbohydrates_string', 'proteins_string'] as Array<keyof typeof item>).every(
      macro => !item[macro] && item[macro] !== 0
    )
  ) return null;

  const fatsKcal = Number(item.fats_string || 0) * 9;
  const carbsKcal = Number(item.carbohydrates_string || 0) * 4;
  const proteinsKcal = Number(item.proteins_string || 0) * 4;

  const serving_size = Number(item.serving_size_string || 0);

  const ckal = (fatsKcal + carbsKcal + proteinsKcal) * serving_size / 100;

  return Number(parseDecimalString(ckal.toString()));
};